import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import Section from '../components/Section';
import Inner from '../components/Styles/Inner';
import SEO from '../components/Seo';
import Image from '../components/Image';
import Typography from '../components/Styles/Typography';
import Chip from '../components/Styles/Chip';
import Avatar from '../components/Avatar';

export default function BlogPost({ data }) {
  const post = data.mdx;

  // function onScroll() {
  //   let options = {
  //     root: 'postTitleRef',
  //     rootMargin: '0px',
  //     threshold: 1.0
  //   }
  //   let callback = (entries, observer) => {
  //     console.log(observer);
  //     entries.forEach(entry => {

  //     });
  //   };
  //   let observer = new IntersectionObserver(callback, options);
  //   console.log(observer);
  // }

  console.log(post.frontmatter.tags);

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.image?.publicURL}
        article={true}
      />
      {/* <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://www.alternativedev.com",
          "name": "ALTdev",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1-508-367-1837",
            "contactType": "Customer Support"
          }
        }
      `}
        </script>
      </Helmet> */}
      <ScrollTitle>
        <Inner>
          <span>{post.frontmatter.title}</span>
        </Inner>
      </ScrollTitle>
      <Styles bottomDivider={true} dividerBottomFill="var(--lightBlue)">
        <article className="post">
          <Section as="header">
            {post.frontmatter.category && (
              <Typography as="span" className="preHeading">
                {post.frontmatter.category}
              </Typography>
            )}
            <Typography as="h1" id="scrollArea">
              {post.frontmatter.title}
            </Typography>
            <div className="post__meta">
              <span className="post__author">
                <Avatar initals="PK" />
                {post.frontmatter.author && post.frontmatter.author}
              </span>
              <span>
                <b>{post.timeToRead} min read</b>
              </span>
            </div>
          </Section>
          <Image image={post.frontmatter.image?.childImageSharp.fluid} />
          <Section>
            <MDXRenderer>{post.body}</MDXRenderer>
          </Section>
        </article>
      </Styles>
    </Layout>
  );
}
export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      timeToRead
      frontmatter {
        title
        date
        image {
          ...ImageFields
        }
        author
        description
        category
        tags
      }
    }
  }
`;

const ScrollTitle = styled.div`
  display: none;
`;

const Styles = styled(Section)`
  article {
    max-width: 1024px;
    margin: 0 auto;
  }
  header {
    text-align: center;
  }
  .gatsby-image-wrapper {
    max-height: 50vh;
    width: 200%;
    transform: translate(-25%);
  }
  .tag:not(.tag:last-child) {
    margin-right: 0.1em;
  }
  .post__meta {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
  }
  .post__author {
    display: flex;
    align-content: center;
    align-items: center;
    gap: 0.5em;
  }
`;
