import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

function Avatar({ className, image, imageAltText, initals }) {
  return (
    <Styles className={className}>
      <GatsbyImage image={image} altText={imageAltText} />
      {!image && initals && initals}
    </Styles>
  );
}

const Styles = styled.span`
  display: inline-block;
  width: 48px;
  height: 48px;
  background: var(--blue);
  color: white;
  border-radius: 9999em;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  .gatsby-image-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 9999em;
  }
`;

export default Avatar;
